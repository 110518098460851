import React, { useCallback, useEffect, useRef, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import { ProfileTransactionService } from "@jobintrans/base-ui.services.api-service"
import { useNavigate, useParams } from "react-router-dom"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { TransactionType } from "@jobintrans/base-ui.types.api"
import { Trans, useTranslation } from "react-i18next"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { SentryService } from "services/SentryService"
import IMG from "assets/images/add_offer_confirmation.svg"
import IMG_ERROR from "assets/images/add_offer_error.svg"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"

const SpinnerPage = () => {
  return (
    <Wrapper>
      <Spinner $size="40px" />
    </Wrapper>
  )
}

const TransactionStatus = () => {
  const { offerId = "", transactionId = "" } = useParams()
  const dispatch = useDispatch()
  const interval = useRef()
  const { t, i18n } = useTranslation(["add-offer"])
  const navigate = useNavigate()

  const [repaidLoading, setRepaidLoading] = useState(false)
  const [data, setData] = useState<TransactionType | null>(null)

  const handleSeeOffer = async () => {
    window.location.replace(`${process.env.REACT_APP_DOMAIN}/${i18n.language}/offers/${offerId}`)
  }

  const handleGoToPanel = async () => {
    navigate("/")
  }

  const fetchStatus = useCallback(async () => {
    try {
      const res = await ProfileTransactionService.getStatus(offerId, transactionId)
      setData(res)
      console.log(res)
      if (res.payment?.status === "NEW" || res.payment?.status === "PENDING") {
        window.location.href = res.payment.redirectUrl
      }
    } catch (e) {
      SentryService.error("[ERROR ProfileTransactionService.getStatus]:", e)
      navigate("/")
    }
  }, [offerId, transactionId])

  const repaidTransaction = useCallback(async () => {
    try {
      setRepaidLoading(true)
      const res = await ProfileTransactionService.repaid(offerId, transactionId)
      setData(res)
      if (res.payment?.status === "NEW" || res.payment?.status === "PENDING") {
        window.location.href = res.payment.redirectUrl
      }
    } catch (e) {
      SentryService.error("[ERROR ProfileTransactionService.repaid]:", e)
      navigate("/")
    } finally {
      setRepaidLoading(false)
    }
  }, [offerId, transactionId])

  useEffect(() => {
    // interval.current = setInterval(fetchStatus, 1500)
    // return () => {
    //   clearInterval(interval.current)
    // }
    fetchStatus()
  }, [])

  if (data?.status === "unpaid" && data.paymentMethodType !== "pro_forma") {
    if (data.payment.status === "NEW" || data.payment.status === "PENDING") return <SpinnerPage />
    else {
      return (
        <Modal
          show={true}
          icon="clock"
          title={t("transaction-status.error.title")}
          text={t("transaction-status.error.text")}
          $maxWidth="550px"
          showBackground={false}
          helperElement={
            <ChangeLanguageComponent>
              <AddOfferHelperText />
            </ChangeLanguageComponent>
          }
        >
          <>
            <Alert $type="error">
              <Trans
                i18nKey={t("transaction-status.unpaid-proforma.info")}
                components={{
                  href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
                }}
              />
            </Alert>
            <StyledImg src={IMG_ERROR} />
            <Button
              $width="100%"
              $type="secondary"
              loading={repaidLoading}
              onClick={repaidTransaction}
              $marginBottom="XS"
            >
              {t("transaction-status.common.repaid")}
            </Button>
            <Button $width="100%" $type="third" onClick={handleGoToPanel}>
              {t("transaction-status.common.go-to-panel")}
            </Button>
          </>
        </Modal>
      )
    }
  }

  if (data?.status === "unpaid" && data.paymentMethodType === "pro_forma")
    return (
      <Modal
        show={true}
        icon="clock"
        title={t("transaction-status.unpaid-proforma.title")}
        text={t("transaction-status.unpaid-proforma.text")}
        $maxWidth="550px"
        showBackground={false}
        helperElement={
          <ChangeLanguageComponent>
            <AddOfferHelperText />
          </ChangeLanguageComponent>
        }
      >
        <>
          <Alert $type="warning">
            <Trans
              i18nKey={t("transaction-status.unpaid-proforma.info")}
              components={{
                href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
              }}
            />
          </Alert>
          <Box $padding="M">
            <Spinner $size="40px" />
          </Box>
          <Button $width="100%" $type="secondary" onClick={handleGoToPanel}>
            {t("transaction-status.common.go-to-panel")}
          </Button>
        </>
      </Modal>
    )

  if (data?.status === "paid") {
    return (
      <Modal
        show={true}
        icon="verified"
        title={t("transaction-status.paid.title")}
        text={t("transaction-status.paid.text")}
        $maxWidth="550px"
        showBackground={false}
      >
        <>
          <StyledImg src={IMG} />
          <StyledGrid>
            <Button $width="100%" $type="secondary" onClick={handleSeeOffer}>
              {t("transaction-status.common.see-offer")}
            </Button>
            <Button onClick={handleGoToPanel}>{t("transaction-status.common.go-to-panel")}</Button>
          </StyledGrid>
        </>
      </Modal>
    )
  }

  return <SpinnerPage />
}

export default TransactionStatus

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const StyledImg = styled.img`
  height: 200px;
  width: max-content;
  display: block;
  margin: ${({ theme }) => theme.space.S} auto;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
`
