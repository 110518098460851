import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { Label } from "@jobintrans/base-ui.components.atoms.typography"
import Button, { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { ProfileSurveyService } from "@jobintrans/base-ui.services.api-service"
import { useTranslation } from "react-i18next"
import { SurveyType } from "@jobintrans/base-ui.types.api"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { SentryService } from "services/SentryService"
import { formatDate } from "utils/date"
import AddSurveyModal from "views/Profile/components/modals/AddSurveyModal"
import SurveyModal from "views/Profile/components/modals/SurveyModal"

const SurveyBlock = ({ survey, onOpen }: { survey: SurveyType; onOpen: () => void }) => {
  const { t, i18n } = useTranslation(["panel"])

  return (
    <SurveyWrapper>
      <Label $marginBottom="0">{survey.name}</Label>
      <Label $type="secondary" $marginBottom="0">
        {formatDate(survey.dtCreated, i18n.language)}
      </Label>
      <Row $justifyContent="center" $alignItems="center">
        <IconButton
          $size="45px"
          $borderRadius="borderRadius"
          $iconSize="XL"
          $background="background"
          icon="eye"
          onClick={onOpen}
          ariaLabel="See"
        />
      </Row>
    </SurveyWrapper>
  )
}

const ProfileSurveysView = () => {
  const { t } = useTranslation(["panel", "form", "common", "data"])

  const [modal, setModal] = useState<boolean | string>(false)
  const [openSurvey, setOpenSurvey] = useState<SurveyType | null>(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<SurveyType[]>([])

  const fetchSurveys = useCallback(async (newPage: number) => {
    try {
      setPage(0)
      setLoading(true)
      const { content, totalPages } = await ProfileSurveyService.getSurveys(newPage)
      setTotalPages(totalPages)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR ProfileSurveyService.getSurveys]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchSurveys(page)
  }, [page])

  const handleCloseModal = () => {
    setModal(false)
    fetchSurveys(0)
  }

  return (
    <>
      <Wrapper>
        <Row $justifyContent="flex-end" $marginBottom="S">
          <Button icon="plus" onClick={() => setModal("add_survey")}>
            {t("profile.surveys.add-survey")}
          </Button>
        </Row>
        <Content>
          {loading && (
            <Row $justifyContent="center">
              <Spinner $size="30px" />
            </Row>
          )}
          {!loading &&
            data.length > 0 &&
            data.map((el, index) => (
              <SurveyBlock key={index} survey={el} onOpen={() => setOpenSurvey(el)} />
            ))}
          {/*{!loading && data.length > 0 && (*/}
          {/*  <Pagination defaultPage={page + 1} totalPages={totalPages} onChange={e => setPage(e - 1)} />*/}
          {/*)}*/}
        </Content>
      </Wrapper>
      <SurveyModal data={openSurvey} setData={() => setOpenSurvey(null)} />
      <AddSurveyModal show={modal === "add_survey"} setShow={handleCloseModal} />
    </>
  )
}

export default ProfileSurveysView

const SurveyWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  padding-left: ${({ theme }) => theme.space.S};
  grid-gap: ${({ theme }) => theme.space.XS};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  display: grid;
  grid-template-columns: 1fr max-content max-content;
`

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
`
