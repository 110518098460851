import React from "react"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import styled from "styled-components"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { useTranslation } from "react-i18next"
import { SurveyAnswerType } from "@jobintrans/base-ui.types.api"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { useCustomQuery } from "hooks/useCustomQuery"

const OfferSurveyAnswerModal = () => {
  const { t } = useTranslation(["panel"])
  const navigate = useNavigate()
  const { offerId = "", applicationId = "" } = useParams()

  const { isLoading, data } = useCustomQuery<SurveyAnswerType>(
    { key: ["survey", offerId, applicationId] },
    () => ProfileOfferService.getOfferApplicationSurvey(offerId, applicationId),
  )

  return (
    <Modal
      show={true}
      setShow={() => navigate(`/panel/offers/${offerId}/applications`)}
      type="center"
      title={data?.name}
      $maxWidth="600px"
    >
      <Wrapper>
        {isLoading ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <Content>
            {data?.answers.map((el, index) => {
              return (
                <AnswerWrapper key={index}>
                  <P $small $marginBottom="XS">
                    {el.title}
                  </P>
                  <Label $marginBottom="0">{`${el.answer}`}</Label>
                </AnswerWrapper>
              )
            })}
          </Content>
        )}
      </Wrapper>
    </Modal>
  )
}

export default OfferSurveyAnswerModal

const AnswerWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Wrapper = styled(Box)``
