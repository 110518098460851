import React from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { AdminProfileType } from "@jobintrans/base-ui.types.api"
import { translate } from "@jobintrans/base-ui.data"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import Icon from "@jobintrans/base-ui.components.atoms.icon"
import { useNavigate } from "react-router-dom"
import Status from "components/atoms/Status/Status"
import { formatDate } from "utils/date"

const AdminProfileBlock = ({ data }: { data: AdminProfileType }) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Box>
          <HeaderWrapper>
            <ProfileImageWrapper>
              {data.type === "personal" && <Icon $size="20px" icon="user" />}
              {data.type === "company" && data.logo && <img src={data.logo} />}
            </ProfileImageWrapper>
            <Box $flex={1}>
              <Row $justifyContent="space-between">
                <Status label={data.status} />
                <Row>
                  <IconButton
                    icon="edit"
                    $background="none"
                    onClick={() => navigate(data.id)}
                    ariaLabel="Edit"
                  />
                </Row>
              </Row>
              <LabelH2 $marginBottom="0" $marginTop="XXS">
                {data.name}
              </LabelH2>
            </Box>
          </HeaderWrapper>
          <TagsWrapper>
            {data.companyIndustrie && (
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="worktype"
                $iconColor="primary"
                label={translate(data.companyIndustrie)}
              />
            )}
            {data.companySize && (
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="users"
                $iconColor="primary"
                label={translate(data.companySize)}
              />
            )}
            {data.email && (
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="email"
                $iconColor="primary"
                label={data.email}
              />
            )}
            {data.dtCreated && (
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="clock-check"
                $iconColor="primary"
                label={formatDate(data.dtCreated)}
              />
            )}
          </TagsWrapper>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default AdminProfileBlock

const ButtonsWrapper = styled(Box)<{
  $status: string
}>`
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.XS};

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: ${({ $status }) => ($status === "active" ? "1fr 1fr" : "1fr")};

    button {
      width: 100%;
    }
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  flex-wrap: wrap;
`

const ProfileImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  background: ${({ theme }) => theme.color.background};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const HeaderWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-bottom: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)``

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  background: white;
`
