import React, { useCallback, useEffect, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { getPlan, translate } from "@jobintrans/base-ui.data"
import { LabelH3, P } from "@jobintrans/base-ui.components.atoms.typography"
import Button from "@jobintrans/base-ui.components.atoms.button"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import TextCheckbox from "@jobintrans/base-ui.components.atoms.text-checkbox"
import { LocalStorageService, ToolsService } from "@jobintrans/base-ui.services.api-service"
import { formatPrice } from "@jobintrans/base-ui.utils.number"
import SelectRow from "@jobintrans/base-ui.components.molecules.select-row"
import { DiscountType } from "@jobintrans/base-ui.types.api"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { Trans, useTranslation } from "react-i18next"
import { RootState } from "store/store"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import { logout } from "store/auth"
import LOGO_TRANSFER from "assets/images/payment_method_transfer.png"
import LOGO_BLIK from "assets/images/payment_method_blik.svg"
import LOGO_CARD from "assets/images/payment_method_card.png"
import { SentryService } from "services/SentryService"
import { useCustomQuery } from "hooks/useCustomQuery"
// import Input from "@jobintrans/base-ui.components.atoms.input"

const AddOfferBasicInfo = ({ state, loading, onBack, onChange }: any) => {
  const { t, i18n } = useTranslation(["add-offer", "common"])
  const dispatch = useDispatch()

  const { data: transactionsNotices } = useCustomQuery<[{ title: string; content: string }]>(
    { key: ["transaction-notice", i18n.language] },
    () => ToolsService.getTransactionsNotices(i18n.language),
  )

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const isJumper = state.type === "jumper"
  const defaultCountryCode =
    profile?.type === "company" ? profile?.company?.countryCode : profile?.address?.countryCode
  const isPoland = defaultCountryCode === "PL"

  const [loadingDiscount, setLoadingDiscount] = useState(true)
  const [code, setCode] = useState<null | DiscountType>(null)
  const [paymentMethod, setPaymentMethod] = useState(isPoland ? "transfer" : "card")

  const checkbox1 = watch("checkbox1")
  const checkbox2 = watch("checkbox2")

  const plan = getPlan(state.type, state.plan)

  const onSubmit = useCallback(async () => {
    onChange(paymentMethod)
  }, [onChange, paymentMethod])

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  const calculatePrice = (currency: "pl" | "eur") => {
    const price = plan.price[currency].value
    let codeDiscount = 100
    let monthDiscount = 100

    if (code) code?.type === "post" ? (codeDiscount = 0) : (codeDiscount -= code.value)

    if (state.period === "3") {
      monthDiscount -= 10
    } else if (state.period === "6") {
      monthDiscount -= 20
    }

    return state.period * (price * (codeDiscount / 100) * (monthDiscount / 100))
  }

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const discount = await ToolsService.getDiscount(state.code)
        setCode(discount)
      } catch (e) {
        SentryService.error("[ERROR ToolsService.getDiscount]:", e)
      } finally {
        setLoadingDiscount(false)
      }
    }

    if (state.code && state.code != "") {
      fetchDiscount()
    } else {
      setLoadingDiscount(false)
    }
  }, [])

  const price = calculatePrice(i18n.language === "pl" ? "pl" : "eur")

  const vat = profile?.company?.countryCode === "PL" ? 23 : 0

  if (loadingDiscount) {
    return <Spinner />
  }

  return (
    <Modal
      show={true}
      icon="plus-circle"
      title={t("summary.title")}
      text={t("summary.text")}
      $maxWidth="900px"
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <AddOfferHelperText />
        </ChangeLanguageComponent>
      }
      helperButton={{
        text: t("logout", { ns: "common" }),
        onClick: handleLogOut,
      }}
      bottomElement={
        <Row $justifyContent="flex-end">
          <Button $type="secondary" onClick={onBack} $marginRight="S">
            {t("back", { ns: "common" })}
          </Button>
          <Button loading={loading} onClick={handleSubmit(onSubmit)}>
            {t("summary.submit")}
          </Button>
        </Row>
      }
    >
      <>
        <Accordion $marginBottom="M" title={state.name}>
          <TagsWrapper>
            <Tag
              label={`${
                state.category === "drivers"
                  ? `${t(state.category, { ns: "data" })}, ${t(state.subcategory, { ns: "data" })}`
                  : t(state.subcategory, { ns: "data" })
              }`}
              icon="worktype"
              $iconColor="primary"
            />
            <Tag label={state?.address?.name} icon="pin" $iconColor="primary" />
            {!isJumper && state.category === "drivers" && state?.drivingAreas.length > 0 && (
              <>
                <Tag
                  label={state?.drivingAreas.map((el: string) => t(el, { ns: "data" })).join(", ")}
                  icon="route"
                  $iconColor="primary"
                />
              </>
            )}
            {state.switches?.paymentContract && (
              <Tag
                label={
                  state.paymentCheckboxes?.paymentContract
                    ? `${state.paymentContractFrom} - ${state.paymentContractTo} ${translate(
                        state.paymentCurrency,
                      )}`
                    : `${state.paymentContractPerDay} ${translate(state.paymentCurrency)}/dzień`
                }
                icon="sallary"
                $iconColor="primary"
              />
            )}
            {state.switches?.paymentB2B && (
              <Tag
                label={
                  state.paymentCheckboxes?.paymentB2B
                    ? `${state.paymentB2BFrom} - ${state.paymentB2BTo} ${translate(
                        state.paymentCurrency,
                      )}`
                    : `${state.paymentB2BPerDay} ${translate(state.paymentCurrency)}/dzień`
                }
                icon="sallary"
                $iconColor="primary"
              />
            )}
            {state.languages.length > 0 && (
              <>
                <Tag
                  label={state?.languages.map((el: string) => t(el, { ns: "data" })).join(", ")}
                  icon="language"
                  $iconColor="primary"
                />
              </>
            )}
          </TagsWrapper>
        </Accordion>
        {profile && profile.type === "company" && profile.company && (
          <Accordion $marginBottom="M" title={t("summary.company.title")}>
            <CompanyWrapper>
              <P label={t("summary.company.name")} $marginBottom="0">
                {profile.company.name}
              </P>
              <P label={t("summary.company.address")} $marginBottom="0">
                {`${profile.company.address} ${profile.company.postCode}, ${
                  profile.company.city
                }\n${profile.company.countryCode.toUpperCase()}`}
              </P>
              <P label={t("summary.company.number")} $marginBottom="0">
                {profile.company.countryCode.toUpperCase()} {profile.company.number}
              </P>
              <Box>
                <P label={t("email", { ns: "form" })} $marginBottom="0">
                  {user?.username}
                </P>
                <P label={t("phone", { ns: "form" })} $marginBottom="0">
                  {profile.phone}
                </P>
              </Box>
            </CompanyWrapper>
          </Accordion>
        )}
        {profile && profile.type === "personal" && (
          <Accordion $marginBottom="M" title={t("summary.personal.title")}>
            <PersonalWrapper>
              <P label={t("summary.personal.name")} $marginBottom="0">
                {profile.name}
              </P>
              <P label={t("summary.personal.address")} $marginBottom="0">
                {`${profile.address.address}\n${profile.address.postCode}, ${
                  profile.address.city
                }\n${profile.address.countryCode.toUpperCase()}`}
              </P>
              <Box>
                <P label={t("email", { ns: "form" })} $marginBottom="0">
                  {user?.username}
                </P>
                <P label={t("phone", { ns: "form" })} $marginBottom="0">
                  {profile.phone}
                </P>
              </Box>
            </PersonalWrapper>
          </Accordion>
        )}
        <Accordion $marginBottom="M" title={t("summary.summary.title")}>
          {code && (
            <Alert $type="success" $marginBottom="S">
              <Trans
                i18nKey={t("summary.summary.code")}
                values={{
                  code: code.code,
                  value: code.type === "percentage" ? `${code.value}` : "100",
                }}
                components={{
                  b: <b />,
                }}
              />
            </Alert>
          )}
          <InvoiceWrapper>
            <P label={t("summary.summary.service")} $marginBottom="0">
              {t(`basket.plans.${plan.slug}.title`, { ns: "add-offer" })} ({state.period}{" "}
              {t("summary.summary.msc", { ns: "add-offer" })})
            </P>
            <P label={t("summary.summary.nett")} $marginBottom="0">
              {formatPrice(price)} {i18n.language === "pl" ? "PLN" : "EURO"}
            </P>
            <P label={t("summary.summary.vat")} $marginBottom="0">
              {vat}%
            </P>
            <P label={t("summary.summary.gross")} $marginBottom="0">
              {formatPrice(price * Number(`1.${vat}`))} {i18n.language === "pl" ? "PLN" : "EURO"}
            </P>
          </InvoiceWrapper>
        </Accordion>
        {price !== 0 && (
          <Accordion $marginBottom="M" title={t("summary.payment.title")}>
            <PaymentMethodList>
              {isPoland && (
                <>
                  <SelectRow
                    checked={paymentMethod === "transfer"}
                    label={
                      <LabelH3 $marginBottom="0">{t("summary.payment.fast-transfer")}</LabelH3>
                    }
                    value={<img height={25} src={LOGO_TRANSFER} style={{ display: "block" }} />}
                    onClick={() => setPaymentMethod("transfer")}
                  />
                  <SelectRow
                    checked={paymentMethod === "blik"}
                    label={<LabelH3 $marginBottom="0">{t("summary.payment.blik")}</LabelH3>}
                    value={
                      <TransferRow>
                        <img height={25} src={LOGO_BLIK} style={{ display: "block" }} />
                      </TransferRow>
                    }
                    onClick={() => setPaymentMethod("blik")}
                  />
                </>
              )}
              <SelectRow
                checked={paymentMethod === "card"}
                label={<LabelH3 $marginBottom="0">{t("summary.payment.card")}</LabelH3>}
                value={<img height={25} src={LOGO_CARD} style={{ display: "block" }} />}
                onClick={() => setPaymentMethod("card")}
              />
              <SelectRow
                checked={paymentMethod === "pro_forma"}
                label={<LabelH3 $marginBottom="0">{t("summary.payment.pro-forma")}</LabelH3>}
                onClick={() => setPaymentMethod("pro_forma")}
              />
            </PaymentMethodList>
          </Accordion>
        )}
        <Box $marginTop="M" $marginBottom="M">
          <TextCheckbox
            name="checkbox1"
            value={checkbox1}
            error={errors.checkbox1}
            rhf={register("checkbox1", {
              required: true,
            })}
            $marginBottom="S"
          >
            *
            <Trans
              i18nKey={t("legal.accept-terms", { ns: "form" })}
              components={{
                terms: <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/terms" />,
                privacy: (
                  <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/privacy" />
                ),
              }}
            />
          </TextCheckbox>
          <TextCheckbox
            name="checkbox2"
            value={checkbox2}
            error={errors.checkbox2}
            rhf={register("checkbox2", {
              required: true,
            })}
            $marginBottom="S"
          >
            <div
              dangerouslySetInnerHTML={{ __html: `*${t("legal.accept-payment", { ns: "form" })}` }}
            />
          </TextCheckbox>
          {(paymentMethod === "card" || paymentMethod === "blik") &&
            transactionsNotices &&
            transactionsNotices?.map((item, index) => (
              <TransferNoticeP
                $marginBottom="S"
                key={index}
                dangerouslySetInnerHTML={{ __html: `${item.title} - ${item.content}` }}
              />
            ))}
        </Box>
      </>
    </Modal>
  )
}

export default AddOfferBasicInfo

const TransferRow = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.font.S};
  align-items: center;
`

const TransferNoticeP = styled(Box)`
  font-size: ${({ theme }) => theme.font.XS};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.light};

  a,
  span {
    color: ${({ theme }) => theme.color.primary};
  }
`

const PaymentMethodList = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const InvoiceWrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;

    > *:first-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    > *:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
`

const PersonalWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const CompanyWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: ${({ theme }) => theme.space.M};
  grid-row-gap: ${({ theme }) => theme.space.S};
`
