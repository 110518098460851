import React from "react"
import styled from "styled-components"
import { Outlet } from "react-router-dom"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { useTranslation } from "react-i18next"
import PanelLayoutNavigation from "./PanelLayoutNavigation"

const PanelLayout = ({ children }: any) => {
  const { i18n } = useTranslation()

  return (
    <Wrapper>
      <PanelLayoutNavigation />
      <Content>
        <InnerContent className="hide_scroll">
          <Outlet />
        </InnerContent>
      </Content>
      <DisableMobile>
        <IconBox icon="lock" />
        <LabelH2 $marginTop="M" $textAlign="center">
          Otwórz panel korzystając z urządzenia o wiekszej rozdzielczości
        </LabelH2>
      </DisableMobile>
    </Wrapper>
  )
}

export default PanelLayout

const DisableMobile = styled.div`
  background: white;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: ${({ theme }) => theme.space.M};
  flex-direction: column;
  z-index: 999;

  @media (max-width: 950px) {
    display: flex;
  }
`

const InnerContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadiusLarge};
  border: 1px solid ${({ theme }) => theme.color.borderLight};
`

const Content = styled.div`
  flex: 1 1;
  height: 100vh;
  padding: ${({ theme }) => theme.space.XS};
  padding-left: 0;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.color.background};
  display: flex;
`
