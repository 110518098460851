import React from "react"
import styled from "styled-components"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { BaseStyles } from "@jobintrans/base-ui.types.base-styles"

const LoaderBlock = ({
  $height,
  ...rest
}: {
  $height: number
} & BaseStyles) => {
  return <LoaderWrapper $height={$height} {...rest} />
}

export default LoaderBlock

const LoaderWrapper = styled(Box)`
  height: ${({ $height }) => `${$height}px`};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: white;
  background: linear-gradient(
    to right,
    white 0%,
    ${({ theme }) => theme.color.background} 25%,
    ${({ theme }) => theme.color.backgroundDarker} 50%,
    ${({ theme }) => theme.color.background} 75%,
    white 100%
  );
  background-size: 1000px 640px;

  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`
