import React, { useEffect, useState } from "react"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { useTranslation } from "react-i18next"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { AdminOffersService } from "@jobintrans/base-ui.services.api-service"
import { OfferAnalytics } from "@jobintrans/base-ui.types.api"
import { InfoContent, Wrapper } from "components/shared/InfoComponents"
import { SentryService } from "services/SentryService"

const AdminOfferAnalyticsModal = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(["panel", "data"])
  const { offerId = "" } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<null | OfferAnalytics>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const report = await AdminOffersService.getAnalytics(offerId)
        setData(report)
        setLoading(false)
      } catch (e) {
        SentryService.error("[ERROR AdminOffersService.getAnalytics]:", e)
      }
    }

    fetchData()
  }, [])

  return (
    <Modal
      show={true}
      setShow={() => navigate(`/panel/offers/${offerId}`)}
      type="center"
      icon="speedometer"
      title={t(`offers.offer.analytics.title`)}
      $maxWidth="500px"
    >
      <>
        {loading || !data ? (
          <Box $marginTop="XL" $marginBottom="XL">
            <Spinner />
          </Box>
        ) : (
          <Wrapper>
            <InfoContent>
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="eye"
                $iconColor="primary"
                sublabel={t(`offers.offer.analytics.types.view`)}
                $width="100%"
                label={`${data.view ?? 0}`}
              />
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="bell"
                $iconColor="primary"
                sublabel={t(`offers.offer.analytics.types.notification`)}
                $width="100%"
                label={`${data.notification ?? 0}`}
              />
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="phone"
                $iconColor="primary"
                sublabel={t(`offers.offer.analytics.types.show_phone`)}
                $width="100%"
                label={`${data.show_phone ?? 0}`}
              />
              {/*<Tag*/}
              {/*  type="secondary"*/}
              {/*  showBorder={false}*/}
              {/*  background="background"*/}
              {/*  icon="phone"*/}
              {/*  iconColor="primary"*/}
              {/*  sublabel={t(`offers.offer.analytics.types.phone`)}*/}
              {/*  width="100%"*/}
              {/*  label={`${data.phone ?? 0}`}*/}
              {/*/>*/}
              <Tag
                $type="secondary"
                $showBorder={false}
                $background="background"
                icon="email"
                $iconColor="primary"
                sublabel={t(`offers.offer.analytics.types.show_email`)}
                $width="100%"
                label={`${data.show_email ?? 0}`}
              />
              {data.external_apply && data.external_apply > 0 && (
                <Tag
                  $type="secondary"
                  $showBorder={false}
                  $background="background"
                  icon="email"
                  $iconColor="primary"
                  sublabel={t(`offers.offer.analytics.types.external_apply`)}
                  $width="100%"
                  label={`${data.external_apply ?? 0}`}
                />
              )}
              {/*<Tag*/}
              {/*  type="secondary"*/}
              {/*  showBorder={false}*/}
              {/*  background="background"*/}
              {/*  icon="email-open"*/}
              {/*  iconColor="primary"*/}
              {/*  sublabel={t(`offers.offer.analytics.types.email`)}*/}
              {/*  width="100%"*/}
              {/*  label={`${data.email ?? 0}`}*/}
              {/*/>*/}
            </InfoContent>
          </Wrapper>
        )}
      </>
    </Modal>
  )
}

export default AdminOfferAnalyticsModal
