import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

const ToolSendSMS = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phone: "",
      type: "fb-group",
      message: "",
    },
  })

  const type = watch("type")

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    const { phone, message, code, ...rest } = el
    try {
      await AdminToolsService.sendSMS(phone, message.replace(/%/g, "%25").replace(/\n/g, "%0A"))
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.sendSMS]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (type === "welcome-fb") {
      setValue(
        "message",
        `Cześć,\nzauważyliśmy twoje ogłoszenia pracy na grupie Facebookowej. Chcieliśmy Cię zaprosić do dodania ogłoszenia na naszej platformie jobintrans.eu ze specjalnym kodem rabatowym "FB25" na 25%.\nTo miejsce z ogłoszeniami pracy przeznaczone tylko dla branży TSL.\nZespół, JobInTrans.eu`,
      )
    } else if (type === "de-welcome") {
      setValue(
        "message",
        `Hallo,\nwir haben bemerkt, dass Sie nach Mitarbeitern aus Polen suchen. Wir möchten Sie einladen, Ihre Stellenanzeige auf unserer Plattform jobintrans.eu/de zu veröffentlichen.\nDies ist ein Ort für Stellenanzeigen, der ausschließlich für die Transport-, Speditions- und Logistikbranche bestimmt ist.\nDas Team von JobInTrans.eu`,
      )
    }
  }, [type])

  return (
    <Accordion title="Wyślij SMS">
      {response === "error" && (
        <Alert $type="error" $small $marginBottom="S">
          Nie udało się wysłać SMS, spróbuj podać inny numer telefonu.
        </Alert>
      )}
      {response === "success" && (
        <Alert $type="success" $small $marginBottom="S">
          SMS został pomyślnie wysłany!
        </Alert>
      )}
      <Input
        name="phone"
        label="Numer telefonu*"
        error={errors?.phone}
        value={watch("phone")}
        rhf={register("phone", {
          required: true,
        })}
        $marginBottom="S"
      />
      <Select
        name="type"
        label="Szablon*"
        options={[
          ["Zaproszenie do dodania oferty (FB)", "welcome-fb"],
          ["[DE] Zaproszenie do dodania oferty", "de-welcome"],
        ]}
        error={errors.type}
        value={watch("type")}
        rhf={register("type", {
          required: true,
        })}
        $marginBottom="S"
      />
      <Input
        name="message"
        label="Wiadomość*"
        error={errors.message}
        multiline
        rows={4}
        rhf={register("message", {
          required: true,
        })}
        $marginBottom="S"
      />
      <Button $marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
        Wyślij
      </Button>
    </Accordion>
  )
}

export default ToolSendSMS
