import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { AdminOfferType } from "@jobintrans/base-ui.types.api"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { AdminOffersService } from "@jobintrans/base-ui.services.api-service"
import { Pagination } from "@jobintrans/base-ui.components.molecules.pagination"
import AdminOfferBlock from "components/shared/AdminOfferBlock"
import { SentryService } from "services/SentryService"

const OffersList = () => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<AdminOfferType[]>([])

  const fetchOffers = useCallback(async (newPage: number) => {
    try {
      setLoading(true)
      const { content, totalPages } = await AdminOffersService.getOffers(null, null, newPage, 15)
      setData(content)
      setTotalPages(totalPages)
    } catch (e) {
      SentryService.error("[ERROR AdminProfileService.getOffers]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchOffers(page)
  }, [page])

  const handleOnRefresh = () => {
    setPage(0)
    fetchOffers(0)
  }

  if (loading)
    return (
      <Wrapper>
        <Row $justifyContent="center">
          <Spinner $size="30px" />
        </Row>
      </Wrapper>
    )

  return (
    <Wrapper>
      {data.map(el => (
        <AdminOfferBlock key={el.id} data={el} />
      ))}
      {data.length > 0 && (
        <Pagination defaultPage={page + 1} totalPages={totalPages} onChange={e => setPage(e - 1)} />
      )}
    </Wrapper>
  )
}

export default OffersList

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
