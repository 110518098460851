import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

const ToolSendEmailProfile = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm()

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const type = watch("type")

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    try {
      await AdminToolsService.sendEmailProfile(el.type, el.email, el.offerId)
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.sendEmailProfile]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Accordion title="Wyślij e-mail do profilu">
      {response === "error" && (
        <Alert $type="error" $small $marginBottom="S">
          Nie udało się wysłać emaila, spróbuj podać inny adres email.
        </Alert>
      )}
      {response === "success" && (
        <Alert $type="success" $small $marginBottom="S">
          Email został pomyślnie wysłany!
        </Alert>
      )}
      <Select
        name="type"
        label="Typ*"
        options={[["Email potwierdzający ogłoszenie", "offer_confirmation"]]}
        error={errors.type}
        value={type}
        rhf={register("type", {
          required: true,
        })}
        $marginBottom="S"
      />
      <Input
        name="email"
        label="Email*"
        error={errors.email}
        rhf={register("email", {
          required: true,
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny format email",
          },
        })}
        $marginBottom="S"
      />
      {type === "offer_confirmation" && (
        <Input
          name="offerId"
          label="Id oferty*"
          error={errors.offerId}
          rhf={register("offerId", {
            required: true,
          })}
          $marginBottom="S"
        />
      )}
      <Button $marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
        Wyślij
      </Button>
    </Accordion>
  )
}

export default ToolSendEmailProfile
