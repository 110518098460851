import React, { useCallback, useEffect, useState } from "react"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { H1, Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { translate } from "@jobintrans/base-ui.data"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { addSpacesFromPhoneNumber } from "@jobintrans/base-ui.utils.string"
import { AdminProfileType } from "@jobintrans/base-ui.types.api"
import { useNavigate, useParams } from "react-router-dom"
import { AdminProfileService } from "@jobintrans/base-ui.services.api-service"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { InfoContent, InfoWrapper, Wrapper } from "components/shared/InfoComponents"
import { SentryService } from "services/SentryService"
import Status from "components/atoms/Status/Status"
import PlatformLink from "components/shared/PlatformLink"
import Link from "components/atoms/Link/Link"

const AdminProfileModal = () => {
  const navigate = useNavigate()
  const { profileId } = useParams()

  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [changing, setChanging] = useState(false)
  const [data, setData] = useState<null | AdminProfileType>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const profile = await AdminProfileService.getProfile(`${profileId}`)
        setData(profile)
        setLoading(false)
      } catch (e) {
        SentryService.error("[ERROR AdminProfileService.getProfile]:", e)
      }
    }
    fetchData()
  }, [])

  const handleDelete = useCallback(async () => {
    if (confirm(`Czy na pewno chcesz usunąć profil ${data?.name}?`) == true) {
      try {
        setDeleting(true)
        await AdminProfileService.deleteProfile(`${profileId}`)
        setDeleting(false)
        navigate("/", {
          replace: true,
        })
      } catch (e) {
        SentryService.error("[ERROR AdminProfileService.deleteProfile]:", e)
      }
    }
  }, [profileId, data])

  return (
    <Modal
      show={true}
      setShow={() => navigate("/panel/profiles")}
      type="side"
      title="Informacje profilu"
      $maxWidth="700px"
    >
      <>
        {loading || !data ? (
          <Box $marginTop="XL">
            <Spinner />
          </Box>
        ) : (
          <Wrapper>
            <Box $marginBottom="M">
              <Status label={data.status} />
              <H1 $marginTop="XS" $marginBottom="0">
                {data.name}
              </H1>
              {data.type === "company" ? (
                <Box $marginTop="XXS">
                  <PlatformLink status={data.isVisible} to={`/profiles/${data.slug}`} />
                </Box>
              ) : (
                <Tag
                  $type="secondary"
                  $showBorder={false}
                  $background="background"
                  label="Konto prywatne"
                  $marginTop="XS"
                />
              )}
            </Box>
            <InfoWrapper>
              <InfoContent>
                <P label="Email" $marginBottom="0">
                  {data.email}
                </P>
                <P label="Numer telefonu" $marginBottom="0">
                  {addSpacesFromPhoneNumber(data.phone)}
                </P>
                {data.type === "company" && (
                  <>
                    {data.website && (
                      <P label="Strona internetowa" $marginBottom="0">
                        {data.website}
                      </P>
                    )}
                    {data.companyIndustrie && (
                      <P label="Branża firmy" $marginBottom="0">
                        {translate(data.companyIndustrie)}
                      </P>
                    )}
                    {data.companySize && (
                      <P label="Branża firmy" $marginBottom="0">
                        {translate(data.companySize)}
                      </P>
                    )}
                  </>
                )}
              </InfoContent>
            </InfoWrapper>
            <InfoWrapper>
              <Label $display="block" $marginBottom="XS">
                Adres
              </Label>
              <InfoContent>
                <P label="Adres" $marginBottom="0">
                  {data.address.address}
                </P>
                <P label="Miasto" $marginBottom="0">
                  {data.address.city}
                </P>
                <P label="Kod pocztowy" $marginBottom="0">
                  {data.address.postCode}
                </P>
                <P label="Kraj" $marginBottom="0">
                  {translate(data.address.countryCode.toUpperCase())}
                </P>
              </InfoContent>
            </InfoWrapper>
            {data.company && (
              <InfoWrapper>
                <Label $display="block" $marginBottom="XS">
                  Dane firmy
                </Label>
                <InfoContent>
                  <P label="Nazwa firmy" $marginBottom="0">
                    {data.company.name}
                  </P>
                  <P label="NIP" $marginBottom="0">
                    {data.company.number}
                  </P>
                  <P label="Adres" $marginBottom="0">
                    {data.company.address}
                  </P>
                  <P label="Miasto" $marginBottom="0">
                    {data.company.city}
                  </P>
                  <P label="Kod pocztowy" $marginBottom="0">
                    {data.company.postCode}
                  </P>
                  <P label="Kraj" $marginBottom="0">
                    {translate(data.company.countryCode.toUpperCase())}
                  </P>
                </InfoContent>
              </InfoWrapper>
            )}
            <Link icon="plus" $type="secondary" to={`/add-offer/${data.id}`}>
              Dodaj ogłoszenie
            </Link>
            {/*<Button icon="info-circle" type="secondary" marginTop="S" width="100%" onClick={handleChangeToAccount}>*/}
            {/*  Zamień na konto pracownicze*/}
            {/*</Button>*/}
            <Button
              icon="trash"
              $marginTop="S"
              $width="100%"
              $background="red"
              $color="white"
              $hoverBackground="red"
              $hoverColor="white"
              onClick={handleDelete}
            >
              Usuń profil
            </Button>
          </Wrapper>
        )}
      </>
    </Modal>
  )
}

export default AdminProfileModal
