import React, { useCallback, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { PublicService } from "@jobintrans/base-ui.services.api-service"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { useTranslation } from "react-i18next"
import { SentryService } from "services/SentryService"
import { ChangeLanguageComponent } from "components/shared/ModalHelpers"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["login", "common", "form"])
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<string | boolean>(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      setResponse(false)
      await PublicService.changePassword(el.username)
      setResponse("success")
      reset()
    } catch (e) {
      SentryService.error("[ERROR UserService.updatePassword]:", e)
      setResponse("error")
    } finally {
      setLoading(false)
    }
  }, [])

  const handleLogin = useCallback(() => {
    navigate("/login")
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title={t("forgot-password-form.title", { ns: "login" })}
      text={t("forgot-password-form.text", { ns: "login" })}
      $maxWidth="400px"
      showBackground={false}
      helperElement={<ChangeLanguageComponent />}
    >
      <>
        {response === "error" && (
          <Alert $type="error" $small $marginBottom="S">
            {t("forgot-password-form.error", { ns: "login" })}
          </Alert>
        )}
        {response === "success" && (
          <Alert $type="success" $small $marginBottom="S">
            {t("forgot-password-form.success", { ns: "login" })}
          </Alert>
        )}
        <Input
          name="username"
          label={`${t("email", { ns: "form" })}*`}
          placeholder="imie.nazwisko@gmail.com"
          error={errors.username}
          rhf={register("username", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: EMAIL_REGEX,
              message: t("wrong-email", { ns: "form" }),
            },
          })}
          $marginBottom="S"
        />
        <Button $width="100%" $marginBottom="XS" loading={loading} onClick={handleSubmit(onSubmit)}>
          {t("reset-password", { ns: "common" })}
        </Button>
        <Button $type="third" $width="100%" onClick={handleLogin}>
          {t("login", { ns: "common" })}
        </Button>
      </>
    </Modal>
  )
}

export default ForgotPassword
