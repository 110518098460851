import React from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { OfferType } from "@jobintrans/base-ui.types.api"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { H2 } from "@jobintrans/base-ui.components.atoms.typography"
import { useCustomQuery } from "hooks/useCustomQuery"
import { RootState } from "store/store"
import PanelLayoutContent from "components/layout/PanelLayoutContent"

const OfferInfo = () => {
  const { t } = useTranslation(["panel", "common"])
  const navigate = useNavigate()
  const { offerId = "" } = useParams()

  const { profile } = useSelector((state: RootState) => state.profile)

  const { isLoading, data } = useCustomQuery<OfferType>({ key: ["offer", offerId] }, () =>
    ProfileOfferService.getOffer(offerId),
  )

  return (
    <>
      <PanelLayoutContent title={t("offers.list.title")} text={t("offers.list.text")}>
        <Row $alignItems="center">
          <IconButton
            $background="background"
            $size="45px"
            $iconSize="L"
            $borderRadius="borderRadius"
            onClick={() => navigate(`/panel/offers`)}
            icon="arrow-left"
            $marginRight="S"
            ariaLabel="Go back"
          />
          <H2 $marginBottom="0">{data?.name}</H2>
        </Row>
        <Outlet />
      </PanelLayoutContent>
    </>
  )
}

export default OfferInfo
