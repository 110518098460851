import React, { useCallback, useEffect, useState } from "react"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { useNavigate, useParams } from "react-router-dom"
import { OfferType } from "@jobintrans/base-ui.types.api"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import EditOfferAdditionalInfo from "./EditOfferAdditionalInfo"
import { SentryService } from "services/SentryService"

const EditOffer = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [step, setStep] = useState(0)
  const [data, setData] = useState<OfferType | null>(null)

  const handleSubmit = useCallback(
    async (state: any) => {
      try {
        setSending(true)
        const obj: any = {
          name: state.name,
          descriptionDuty: state.switches.description1 ? state.descriptions.description1 : null,
          descriptionRequirements: state.switches.description2
            ? state.descriptions.description2
            : null,
          descriptionWeOffer: state.switches.description3 ? state.descriptions.description3 : null,
          drivingAreas: state.drivingAreas,
          workTypes: state.workTypes,
          truckTypes: state.truckTypes,
          directions: state.directions,
          workLocations: state.workLocations,
          languages: state.languages,
          cast: state.cast,
          experience: state.experience[0],
          params: state.params?.filter((e: string) => e !== "uaFriendly") ?? [],
          paymentContract: null,
          paymentB2B: null,
          legalInformationClause: state.legalInformationClause,
          legalFutureRecruitment: state.switches.legalFutureRecruitment
            ? state.legalFutureRecruitment
            : null,
          applyLink: state.applyLink && state.applyLink !== "" ? state.applyLink : null,
          uaFriendly: state.params?.includes("uaFriendly"),
        }

        if (state.switches.paymentContract && state.paymentCheckboxes?.paymentContract) {
          obj.paymentContract = {
            type: "range",
            currency: state.paymentCurrency,
            from: state.paymentContractFrom,
            to: state.paymentContractTo,
          }
        }
        if (state.switches.paymentContract && state.paymentCheckboxes?.paymentContractPerDay) {
          obj.paymentContract = {
            type: "perDay",
            currency: state.paymentCurrency,
            perDay: state.paymentContractPerDay,
          }
        }
        if (state.switches.paymentB2B && state.paymentCheckboxes?.paymentB2B) {
          obj.paymentB2B = {
            type: "range",
            currency: state.paymentCurrency,
            from: state.paymentB2BFrom,
            to: state.paymentB2BTo,
          }
        }
        if (state.switches.paymentB2B && state.paymentCheckboxes?.paymentB2BPerDay) {
          obj.paymentB2B = {
            type: "perDay",
            currency: state.paymentCurrency,
            perDay: state.paymentB2BPerDay,
          }
        }

        await ProfileOfferService.editOffer(`${data?.id}`, obj)
        navigate(`/panel/offers`)
      } catch (e) {
        window.alert("Nie udało się edytować ogłoszenia! Proszę spróbować później.")
        SentryService.error("[ERROR OfferService.editOffer]:", e)
      } finally {
        setSending(false)
      }
    },
    [data],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const offer = await ProfileOfferService.getOffer(`${id}`)
        setData(offer)
      } catch (e) {
        SentryService.error("[ERROR ProfileOfferService.getOffer]:", e)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading || !data) {
    return (
      <Box $padding="M">
        <Spinner $size="40px" />
      </Box>
    )
  }

  return <EditOfferAdditionalInfo loading={sending} data={data} onSubmit={handleSubmit} />
}

export default EditOffer
