import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

const ToolAddAd = ({ id }: { id?: string }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      id: id,
      type: "facebook",
      link: "",
    },
  })

  const type = watch("type")

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    const { id, type, link } = el
    try {
      await AdminToolsService.addAd(id, type, link)
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.addAd]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Accordion title="Dodaj reklamę">
      {response === "error" && (
        <Alert $type="error" $small $marginBottom="S">
          Nie udało się dodać reklamy, spróbuj podać inne ID ogłoszenia.
        </Alert>
      )}
      {response === "success" && (
        <Alert $type="success" $small $marginBottom="S">
          Kampania została pomyślnie dodana!
        </Alert>
      )}
      {!id && (
        <Input
          name="id"
          label="ID ogłoszenia*"
          error={errors?.id}
          value={watch("id")}
          rhf={register("id", {
            required: true,
          })}
          $marginBottom="S"
        />
      )}
      <Select
        name="type"
        label="Rodzaj*"
        options={[
          ["Facebook", "facebook"],
          ["Google", "google"],
          ["Jooble", "jooble"],
        ]}
        error={errors.type}
        value={watch("type")}
        rhf={register("type", {
          required: true,
        })}
        $marginBottom="S"
      />
      <Input
        name="link"
        label="Link"
        error={errors.link}
        rhf={register("link")}
        $marginBottom="S"
      />
      <Button $marginLeft="auto" icon="plus" loading={loading} onClick={handleSubmit(onSubmit)}>
        Dodaj
      </Button>
    </Accordion>
  )
}

export default ToolAddAd
