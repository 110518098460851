import React from "react"
import styled, { css } from "styled-components"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { BaseStyles } from "@jobintrans/base-ui.types.base-styles"
import { useTranslation } from "react-i18next"

const Status = ({
  label,
  ...rest
}: BaseStyles & {
  label: string
}) => {
  const { t } = useTranslation(["data"])
  return (
    <StyledStatus
      {...rest}
      $type="secondary"
      label={t(label)}
      $status={label}
      $showBorder={false}
    />
  )
}

export default Status

const StyledStatus = styled(Tag)<{
  $status: string
}>`
  height: max-content;
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.XS};
  border-radius: 50px;

  ${({ $status }) =>
    ($status === "active" || $status === "paid") &&
    css`
      background: ${({ theme }) => theme.color.greenBackground};

      label {
        color: ${({ theme }) => theme.color.green};
      }
    `};

  ${({ $status }) =>
    (status === "add_offer" ||
      status === "pending" ||
      status === "unpaid" ||
      status === "limited") &&
    css`
      background: ${({ theme }) => theme.color.orangeBackground};

      label {
        color: ${({ theme }) => theme.color.orange};
      }
    `};

  ${({ $status }) =>
    ($status === "deactivated" || $status === "rejected") &&
    css`
      background: ${({ theme }) => theme.color.redBackground};

      label {
        color: ${({ theme }) => theme.color.red};
      }
    `};

  ${({ $status }) =>
    $status === "hidden" &&
    css`
      background: ${({ theme }) => theme.color.orangeBackground};

      label {
        color: ${({ theme }) => theme.color.orange};
      }
    `};
`
