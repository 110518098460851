import React, { useEffect, useState } from "react"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { ApplicationType } from "@jobintrans/base-ui.types.api"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { useTranslation } from "react-i18next"
import Switch from "@jobintrans/base-ui.components.atoms.switch"
import { SentryService } from "services/SentryService"
import OfferApplicationList from "views/Profile/Offers/OfferApplications/components/OfferApplicationList"
import { AnalyticsService } from "services/AnalyticsService"

const OfferApplicationModal = () => {
  const { t } = useTranslation(["panel"])
  const navigate = useNavigate()
  const { offerId = "" } = useParams()

  const [isNotRead, setIsNotRead] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState<number | null>(null)
  const [data, setData] = useState<ApplicationType[]>([])

  const fetchData = async (newPage: number, isNotRead: boolean) => {
    try {
      setLoading(true)
      const { content, totalPages, totalElements } = await ProfileOfferService.getOfferApplications(
        `${offerId}`,
        isNotRead,
        newPage,
      )
      setData(content)
      setTotalPages(totalPages)
      setCount(totalElements)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.getOfferApplications]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page, isNotRead)
  }, [page])

  useEffect(() => {
    AnalyticsService.track("panel_offer_applications_open")
  }, [])

  return (
    <>
      <Wrapper>
        <Row $marginBottom="S" $justifyContent="space-between" $alignItems="flex-end">
          <Box>
            {!loading && (
              <P $marginBottom="0">
                {t("offers.offer.candidates.count")} {count ?? "0"}
              </P>
            )}
          </Box>
          <SwitchWrapper
            $justifyContent="space-between"
            $padding="S"
            $background="background"
            $alignItems="center"
          >
            <Label $marginBottom="0" $marginRight="XS">
              {t("offers.offer.candidates.only-new-cadidates")}
            </Label>
            <Switch
              name="paymentContract"
              value={isNotRead}
              onChange={({ name, value }) => {
                setIsNotRead(value)
                fetchData(0, value)
              }}
            />
          </SwitchWrapper>
        </Row>
        <OfferApplicationList
          offerId={offerId}
          data={data}
          loading={loading}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      </Wrapper>
      <Outlet />
    </>
  )
}

export default OfferApplicationModal

const Wrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.space.M};
`

const SwitchWrapper = styled(Row)`
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
