import React from "react"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { SurveyQuestionType, SurveyType } from "@jobintrans/base-ui.types.api"
import { useNavigate } from "react-router-dom"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import styled from "styled-components"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { Trans, useTranslation } from "react-i18next"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import Alert from "@jobintrans/base-ui.components.molecules.alert"

const QuestionBlock = ({ question }: { question: SurveyQuestionType }) => {
  const { t, i18n } = useTranslation(["panel"])

  let answersString = ""
  if (question.type === "yes_no") {
    answersString = t(`profile.surveys.types.yes_no`)
  } else if (
    question.type === "radio" ||
    question.type === "checkboxes" ||
    question.type === "select"
  ) {
    answersString = question?.params?.answers?.join(", ")
  } else if (question.type === "range") {
    answersString = `${question?.params?.from} - ${question?.params?.to}`
  }

  const hasAnswers = answersString !== ""
  return (
    <SurveyWrapper>
      <Row $justifyContent="space-between" $marginBottom={hasAnswers ? "XS" : "0"}>
        <Label $marginBottom="0">{question.title}</Label>
        <Tag
          $type="secondary"
          $showBorder={false}
          $background="backgroundDarker"
          label={t(`profile.surveys.types.${question.type}`)}
        />
      </Row>
      {hasAnswers && (
        <P $small $marginBottom={0}>
          {t(`profile.surveys.answers`)}: {answersString}
        </P>
      )}
    </SurveyWrapper>
  )
}

const SurveyModal = ({ data, setData }: { data: SurveyType | null; setData: () => void }) => {
  const { t } = useTranslation(["panel"])
  const navigate = useNavigate()

  return (
    <Modal show={!!data} setShow={setData} type="center" title={data?.name} $maxWidth="800px">
      <Wrapper>
        <Alert $type="warning">
          <Trans
            i18nKey={t("profile.surveys.want-to-change")}
            components={{
              href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
            }}
          />
        </Alert>
        {data?.questions.map((question: SurveyQuestionType) => (
          <QuestionBlock key={question.id} question={question} />
        ))}
      </Wrapper>
    </Modal>
  )
}

export default SurveyModal

const SurveyWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
