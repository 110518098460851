import React from "react"
import { Pagination } from "@jobintrans/base-ui.components.molecules.pagination"
import { Box, Column } from "@jobintrans/base-ui.components.atoms._atoms"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import styled from "styled-components"
import { AdminTransactionType } from "@jobintrans/base-ui.types.api"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { Link } from "react-router-dom"
import { formatPrice } from "@jobintrans/base-ui.utils.number"
import LoaderBlock from "components/shared/LoaderBlock"
import Status from "components/atoms/Status/Status"
import { formatDate } from "utils/date"

const TransactionList = ({
  loading,
  data,
  page,
  totalPages,
  setPage,
}: {
  data: AdminTransactionType[]
  loading: boolean
  totalPages: number
  page: number
  setPage: (e: number) => void
}) => {
  const { t, i18n } = useTranslation(["panel"])
  const { t: tData } = useTranslation(["data"])

  const columnHelper = createColumnHelper<AdminTransactionType>()

  const columns = [
    columnHelper.accessor(row => row.status, {
      id: "status",
      header: () => tData("status"),
      cell: info => (
        <StyledPaddingTd>
          <Status label={info.getValue()} />
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.profile.name, {
      id: "profile",
      header: () => tData("profile"),
      cell: info => (
        <StyledPaddingTd>
          <Link to={`/panel/profiles/${info.row.original.profile.id}`}>
            <Tag
              $type="secondary"
              $background="background"
              $showBorder={false}
              label={
                info.getValue().length > 12
                  ? `${info.getValue().substring(0, 12)}...`
                  : info.getValue()
              }
            />
          </Link>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.offer.name, {
      id: "offer",
      header: () => tData("offer"),
      cell: info => (
        <StyledPaddingTd>
          <Link to={`/panel/offers/${info.row.original.offer.id}`}>
            <Tag
              $type="secondary"
              $background="background"
              $showBorder={false}
              label={
                info.getValue().length > 12
                  ? `${info.getValue().substring(0, 12)}...`
                  : info.getValue()
              }
            />
          </Link>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.value, {
      id: "value",
      header: () => tData("value"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {formatPrice(info.getValue())}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.currency, {
      id: "currency",
      header: () => tData("currency"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {info.getValue()}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.discount, {
      id: "discount",
      header: () => tData("discount"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {info.getValue() ?? "-"}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.paymentMethodType, {
      id: "paymentMethodType",
      header: () => tData("paymentMethodType"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {tData(`paymentMethodTypes.${info.getValue()}`)}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.product.type, {
      id: "productType",
      header: () => tData("productType"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {tData(`productTypes.${info.getValue()}`)}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: () => tData("quantity"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {info.getValue()}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.dtModified, {
      id: "dtModified",
      header: () => tData("dtModified"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {formatDate(info.getValue(), i18n.language, "dd/MM/yyyy HH:mm")}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.dtCreated, {
      id: "dtCreated",
      header: () => tData("dtCreated"),
      cell: info => (
        <StyledPaddingTd>
          <Label $type="secondary" $marginBottom="0">
            {formatDate(info.getValue(), i18n.language, "dd/MM/yyyy HH:mm")}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor("id", {
      header: () => undefined,
      cell: info => <></>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {loading ? (
        <Content>
          <LoaderBlock $height={67} />
          <LoaderBlock $height={67} />
          <LoaderBlock $height={67} />
        </Content>
      ) : data.length > 0 ? (
        <>
          <Content>
            <StyledTable>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        <Label $type="secondary" $textAlign="left">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Label>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <StyledTr key={row.original.id}>
                    {row.getVisibleCells().map(cell => (
                      <StyledTd key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </StyledTd>
                    ))}
                  </StyledTr>
                ))}
              </tbody>
            </StyledTable>
          </Content>
          {data.length > 0 && (
            <Pagination
              defaultPage={page + 1}
              totalPages={totalPages}
              onChange={e => setPage(e - 1)}
            />
          )}
        </>
      ) : (
        <Column $alignItems="center">
          <IconBox icon="clock" $marginBottom="S" />
          <P $textAlign="center" $small>
            {t("offers.offer.candidates.empty")}
          </P>
        </Column>
      )}
    </>
  )
}

export default TransactionList

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const StyledTable = styled.table`
  border-spacing: ${({ theme }) => theme.space.XS} ${({ theme }) => theme.space.XS};
`

const StyledTd = styled.td`
  padding: ${({ theme }) => theme.space.XXS};
  min-height: 55px;
`

const StyledPaddingTd = styled.div<{
  shouldHide?: boolean
}>`
  padding: ${({ theme }) => theme.space.XXS};
`

const StyledTr = styled.tr`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
