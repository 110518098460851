import React, { useCallback } from "react"
import styled from "styled-components"

import { Label } from "@jobintrans/base-ui.components.atoms.typography"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import { common } from "@jobintrans/base-ui.components.atoms._atoms"
import { useTranslation } from "react-i18next"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { logout } from "store/auth"
import { initProfileState } from "store/profile"
import Link from "components/atoms/Link/Link"
import LOGO from "assets/images/logo.svg"
import { RootState } from "store/store"
import { locales } from "utils/lang.utils"
import { localeToIcon } from "utils/flags.utils"

const PanelLayoutNavigation = () => {
  const { t, i18n } = useTranslation(["panel", "common"])
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const handleLogout = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
    dispatch(initProfileState())
  }, [])

  const setLanguage = useCallback((lang: string) => {
    LocalStorageService.setObject("lang", lang)
    i18n.changeLanguage(lang)
  }, [])

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={LOGO} />
      </LogoWrapper>
      <NavigationWrapper>
        {user?.role === "ROLE_ADMIN" ? (
          <>
            <Link
              $type="secondary"
              icon="company"
              to="/panel/profiles"
              $justifyContent="flex-start"
            >
              Profile
            </Link>
            <Link $type="secondary" icon="copy" to="/panel/offers" $justifyContent="flex-start">
              Ogłoszenia
            </Link>
            <Link
              $type="secondary"
              icon="sallary"
              to="/panel/transactions"
              $justifyContent="flex-start"
            >
              Transakcje
            </Link>
            <Border />
            <Link $type="secondary" icon="tag-ai" to="/panel/tools" $justifyContent="flex-start">
              Narzędzia
            </Link>
          </>
        ) : (
          <>
            <Link $type="secondary" icon="edit" to="/panel/offers" $justifyContent="flex-start">
              {t("navigation.offers")}
            </Link>
            <Link $type="secondary" icon="company" to="/panel/profile" $justifyContent="flex-start">
              {t("navigation.profile")}
            </Link>
          </>
        )}
      </NavigationWrapper>
      {user?.role === "ROLE_PROFILE" ? (
        <>
          {/*<HelperWrapper>*/}
          {/*  <Icon icon="help" $color="primary" $size="L" />*/}
          {/*  <Box $marginLeft="XS">*/}
          {/*    <Label $type="secondary" $color="primary">*/}
          {/*      {t("support.text")}*/}
          {/*    </Label>*/}
          {/*    <Box $marginTop="S">*/}
          {/*      <a href="tel:+48530207961">*/}
          {/*        <Label $type="secondary" $color="primary">*/}
          {/*          +48 530 207 961*/}
          {/*        </Label>*/}
          {/*      </a>*/}
          {/*    </Box>*/}
          {/*    <Box $marginTop="XS">*/}
          {/*      <a href="mailto:pomoc@jobintrans.eu">*/}
          {/*        <Label $type="secondary" $color="primary">*/}
          {/*          pomoc@jobintrans.eu*/}
          {/*        </Label>*/}
          {/*      </a>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</HelperWrapper>*/}
          <Select
            name="lang"
            options={locales.map(el => [
              `${localeToIcon[el]} ${t(`languages.${el}`, { ns: "common" })}`,
              el,
            ])}
            value={i18n.language}
            onChange={({ value }) => setLanguage(value)}
            $color="second"
            $background="transparent"
            $borderColor="border"
            $marginBottom="S"
          />
          <ProfileWrapper>
            <ProfileContent>
              <ProfileImageWrapper>
                {profile?.logo && <img src={profile?.logo} />}
              </ProfileImageWrapper>
              <ProfileName $color="second" $margin="0" $marginLeft="S">
                {`${
                  profile?.name && profile?.name.length > 13
                    ? `${profile?.name.substring(0, 13)}...`
                    : profile?.name
                }`}
              </ProfileName>
            </ProfileContent>
            <IconButton
              icon="logout"
              $background="none"
              $color="second"
              onClick={handleLogout}
              ariaLabel="Logout"
            />
          </ProfileWrapper>
        </>
      ) : (
        <ProfileWrapper>
          <ProfileContent>
            <Label $color="second" $margin="0" $marginLeft="S">
              {`${user?.username}`}
            </Label>
          </ProfileContent>
          <IconButton
            icon="logout"
            $background="none"
            $color="second"
            onClick={handleLogout}
            ariaLabel="Logout"
          />
        </ProfileWrapper>
      )}
    </Wrapper>
  )
}

export default PanelLayoutNavigation

const HelperWrapper = styled.div`
  padding: ${({ theme }) => theme.space.S};
  background: ${({ theme }) => theme.color.primaryBackground};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  margin-bottom: ${({ theme }) => theme.space.S};
  display: flex;
`

const NavigationWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XXS};
`

const ProfileName = styled(Label)`
  ${common};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  background: ${({ theme }) => theme.color.backgroundDarker};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => theme.space.S} 0;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.space.M} ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};

  img {
    width: 80%;
    max-height: 100px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.S};
`
