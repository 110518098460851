import React, { useCallback, useEffect, useRef, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"

import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import {
  translate,
  WORKLOCATION_ARRAY,
  WORKTYPES_ARRAY,
  DRIVINGAREAS_ARRAY,
  LANGUAGES_ARRAY,
  CURRENCY_ARRAY,
  PARAMS_OTHERS_ARRAY,
  TRUCKTYPES_ARRAY,
  DIRECTIONS_ARRAY,
  CAST_ARRAY,
  EXPERIENCE_ARRAY,
} from "@jobintrans/base-ui.data"
import { H3, H4, P } from "@jobintrans/base-ui.components.atoms.typography"
import Switch from "@jobintrans/base-ui.components.atoms.switch"
import Input, { InputAutocomplete } from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import CheckboxesGroup from "@jobintrans/base-ui.components.atoms.checkboxes-group"
import { LocalStorageService, ProfileSurveyService } from "@jobintrans/base-ui.services.api-service"
import Checkbox from "@jobintrans/base-ui.components.atoms.checkbox"
import { WEBSITE_REGEX } from "@jobintrans/base-ui.utils.regex"
import Select from "@jobintrans/base-ui.components.atoms.select"
import {
  PARAMS_DRIVER_ARRAY,
  WORKTYPES_DRIVER_ARRAY,
} from "@jobintrans/base-ui.data/dist/OfferInformations"
import { useTranslation } from "react-i18next"
import { ShortSurveyType } from "@jobintrans/base-ui.types.api"
import { RootState } from "store/store"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import { logout } from "store/auth"
import RichTextEditor from "components/shared/RichTextEditor/RichTextEditor"
import { formatDate } from "utils/date"
import { SentryService } from "services/SentryService"

const AddOfferAdditioanlInfo = ({ state, onBack, onChange }: any) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation(["add-offer", "common", "data"])
  const { profile } = useSelector((state: RootState) => state.profile)
  const isJumper = state.type === "jumper"
  const legalName = profile?.type === "company" ? profile?.company?.name : profile?.name
  const legalCity = profile?.type === "company" ? profile?.company?.city : profile?.address.city
  const legalAddress =
    profile?.type === "company" ? profile?.company?.address : profile?.address.address

  //SURVEY
  const timeoutId = useRef<number | null>()
  const [loadingSurvey, setLoadingSurvey] = useState<boolean>(false)
  const [surveysOptions, setSurveyOptions] = useState<ShortSurveyType[]>([])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    control,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      name: state.name || "",
      paymentCurrency: "pln",
      paymentContractFrom: state.paymentContractFrom || "",
      paymentContractTo: state.paymentContractTo || "",
      paymentContractPerDay: state.paymentContractPerDay || "",
      paymentB2BFrom: state.paymentB2BFrom || "",
      paymentB2BTo: state.paymentB2BTo || "",
      paymentB2BPerDay: state.paymentB2BPerDay || "",
      description1: state.description1 || "",
      description2: state.description2 || "",
      description3: state.description3 || "",
      legalInformationClause:
        state.legalInformationClause ||
        t("additional-info.legal.info.defaultValue", {
          ns: "add-offer",
          legalName: legalName,
          legalCity,
          legalAddress,
        }),
      legalFutureRecruitment:
        state.legalFutureRecruitment ||
        t("additional-info.legal.future-apply.defaultValue", {
          ns: "add-offer",
        }),
      applyLink: state.applyLink || "",
      survey: state.survey,
    },
  })

  const [drivingAreas, setDrivingAreas] = useState(
    state.drivingAreas || state.category === "drivers" ? ["national", "international"] : [],
  )
  const [workTypes, setWorkTypes] = useState(
    state.workTypes || state.category === "drivers"
      ? ["2_1", "2_2", "3_1", "4_1", "4_2", "6_2"]
      : ["fulltime"],
  )
  const [truckTypes, setTruckTypes] = useState(state.truckTypes)
  const [directions, setDirections] = useState(state.directions)

  const [workLocations, setWorkLocations] = useState(
    state.workLocations || state.category === "drivers" ? [] : ["office"],
  )
  const [languages, setLanguages] = useState(state.languages || ["polish"])
  const [cast, setCast] = useState(state.cast || [])
  const [experience, setExperience] = useState(state.experience ?? ["exp_no"])
  const [params, setParams] = useState(
    state.uaFriendly ? [...state.params, "uaFriendly"] : state.params,
  )
  const [switches, setSwitches] = useState({
    paymentContract: state.switches?.paymentContract ?? true,
    paymentB2B: state.switches?.paymentB2B ?? false,
    description1: true,
    description2: state.switches?.description2 ?? false,
    description3: state.switches?.description3 ?? false,
    legalInformationClause: true,
    legalFutureRecruitment: state.switches?.legalFutureRecruitment ?? false,
  })
  const [paymentCheckboxes, setPaymentCheckboxes] = useState({
    paymentContract: state.paymentCheckboxes?.paymentContract ?? true,
    paymentContractPerDay: state.paymentCheckboxes?.paymentContractPerDay ?? false,
    paymentB2B: state.paymentCheckboxes?.paymentB2B ?? true,
    paymentB2BPerDay: state.paymentCheckboxes?.paymentB2BPerDay ?? false,
  })
  const [descriptions, setDescriptions] = useState({
    description1: state.descriptions?.description1 || "",
    description2: state.descriptions?.description2 || "",
    description3: state.descriptions?.description3 || "",
  })

  const paymentCurrency = watch("paymentCurrency")
  const paymentContractFrom = watch("paymentContractFrom")
  const paymentContractTo = watch("paymentContractTo")
  const paymentContractPerDay = watch("paymentContractPerDay")
  const paymentB2BFrom = watch("paymentB2BFrom")
  const paymentB2BTo = watch("paymentB2BTo")
  const paymentB2BPerDay = watch("paymentB2BPerDay")

  const [surveyInput, setSurveyInput] = useState<string | undefined>(state?.survey?.label || "")

  const onChangeSwitches = ({ name, value }: { name: string; value: boolean }) => {
    setSwitches({
      ...switches,
      [name]: value,
    })
  }

  const onChangePaymentCheckboxes = ({ name, value }: { name: string; value: boolean }) => {
    let paymentContract = paymentCheckboxes.paymentContract
    let paymentContractPerDay = paymentCheckboxes.paymentContractPerDay
    let paymentB2B = paymentCheckboxes.paymentB2B
    let paymentB2BPerDay = paymentCheckboxes.paymentB2BPerDay

    if (name === "paymentContract") {
      paymentContract = value
      paymentContractPerDay = !value
      if (value) {
        unregister("paymentContractPerDay")
      } else {
        unregister("paymentContractFrom")
        unregister("paymentContractTo")
      }
    } else if (name === "paymentContractPerDay") {
      paymentContractPerDay = value
      paymentContract = !value
      if (!value) {
        unregister("paymentContractPerDay")
      } else {
        unregister("paymentContractFrom")
        unregister("paymentContractTo")
      }
    } else if (name === "paymentB2B") {
      paymentB2B = value
      paymentB2BPerDay = !value
      if (value) {
        unregister("paymentB2BPerDay")
      } else {
        unregister("paymentB2BFrom")
        unregister("paymentB2BTo")
      }
    } else if (name === "paymentB2BPerDay") {
      paymentB2BPerDay = value
      paymentB2B = !value
      if (!value) {
        unregister("paymentB2BPerDay")
      } else {
        unregister("paymentB2BFrom")
        unregister("paymentB2BTo")
      }
    }

    setPaymentCheckboxes({
      paymentContract,
      paymentContractPerDay,
      paymentB2BPerDay,
      paymentB2B,
    })
  }

  const onChangePaymentSwitches = ({ name, value }: { name: string; value: boolean }) => {
    let paymentContract = switches.paymentContract
    let paymentB2B = switches.paymentB2B

    if (name === "paymentContract") {
      paymentContract = value
      if (value === false) {
        unregister("paymentContractFrom")
        unregister("paymentContractTo")
        unregister("paymentContractPerDay")
        if (!paymentB2B) {
          paymentB2B = true
        }
      }
    } else {
      paymentB2B = value
      if (value === false) {
        unregister("paymentB2BFrom")
        unregister("paymentB2BTo")
        unregister("paymentB2BPerDay")
        if (!paymentContract) {
          paymentContract = true
        }
      }
    }

    setSwitches({
      ...switches,
      paymentContract,
      paymentB2B,
    })
  }

  const onSubmit = useCallback(
    async (el: any) => {
      let breakFunction = false
      if (switches.paymentContract && paymentCheckboxes.paymentContract) {
        if (Number(paymentContractFrom) > Number(paymentContractTo)) {
          setError(
            "paymentContractFrom",
            {
              type: "custom",
              message: t("additional-info.error.price-contract", { ns: "add-offer" }),
            },
            {
              shouldFocus: true,
            },
          )
          breakFunction = true
        }
      }
      if (switches.paymentB2B && paymentCheckboxes.paymentB2B) {
        if (Number(paymentB2BFrom) > Number(paymentB2BTo)) {
          setError(
            "paymentB2BFrom",
            {
              type: "custom",
              message: t("additional-info.error.price-contract", { ns: "add-offer" }),
            },
            {
              shouldFocus: true,
            },
          )
          breakFunction = true
        }
      }

      if (breakFunction) return

      onChange({
        ...el,
        switches,
        paymentCheckboxes,
        drivingAreas,
        workTypes,
        workLocations,
        truckTypes,
        directions,
        languages,
        descriptions,
        experience,
        cast,
        params,
      })
    },
    [
      paymentCheckboxes,
      switches,
      workLocations,
      experience,
      cast,
      workTypes,
      truckTypes,
      directions,
      languages,
      drivingAreas,
      paymentContractFrom,
      paymentContractTo,
      paymentB2BFrom,
      paymentB2BTo,
      descriptions,
      params,
    ],
  )

  const handleOnChangeRichTextEditor = useCallback(
    ({ target }: { target: { name: string; value: string } }) => {
      setDescriptions({
        ...descriptions,
        [target.name]: target.value,
      })
    },
    [descriptions],
  )

  const searchSurveyAutocomplete = useCallback(async (val: string) => {
    try {
      setLoadingSurvey(true)
      const { content } = await ProfileSurveyService.searchSurveys(val)
      setSurveyOptions(content)
    } catch (e) {
      SentryService.error("[ERROR ProfileSurveyService.searchSurveys]:", e)
    } finally {
      setLoadingSurvey(false)
    }
  }, [])

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      if (surveyInput) {
        searchSurveyAutocomplete(surveyInput)
      }
    }, 1000)
  }, [surveyInput])

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  return (
    <Modal
      show={true}
      icon="plus-circle"
      title={t("additional-info.title")}
      text={t("additional-info.text")}
      $maxWidth="900px"
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <AddOfferHelperText />
        </ChangeLanguageComponent>
      }
      helperButton={{
        text: t("logout", { ns: "common" }),
        onClick: handleLogOut,
      }}
      bottomElement={
        <Row $justifyContent="flex-end">
          <Button $type="secondary" onClick={onBack} $marginRight="S">
            {t("back", { ns: "common" })}
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>{t("next", { ns: "common" })}</Button>
        </Row>
      }
    >
      <>
        <BasicInfoGrid>
          <P label={t("offer.category", { ns: "form" })} $marginBottom="0">
            {`${t(state.category, { ns: "data" })}, ${t(state.subcategory, { ns: "data" })}`}
          </P>
          <P label={t("address", { ns: "form" })} $marginBottom="0">
            {state.address?.name}
            {state?.endAddress && ` -> ${state?.endAddress?.name}`}
          </P>
          {isJumper && (
            <P label={t("date", { ns: "form" })} $marginBottom="0">
              {`${formatDate(state.startDate, i18n.language, "do MMMM yyyy")} -> ${formatDate(
                state.endDate,
                i18n.language,
                "do MMMM yyyy",
              )}`}
            </P>
          )}
        </BasicInfoGrid>
        <BlockWrapper>
          <H3 $marginBottom="0">{t("additional-info.name", { ns: "add-offer" })}</H3>
          <Input
            name="name"
            value={watch("name")}
            error={errors.name}
            rhf={register("name", {
              required: t("required", { ns: "form" }),
            })}
          />
        </BlockWrapper>
        <BlockWrapper>
          <Row $justifyContent="space-between" $alignItems="flex-end">
            <H3 $marginBottom="0">{t("additional-info.price", { ns: "add-offer" })}</H3>
            <Select
              name="paymentCurrency"
              options={CURRENCY_ARRAY}
              error={errors.paymentCurrency}
              value={watch("paymentCurrency")}
              rhf={register("paymentCurrency", {
                required: t("required", { ns: "form" }),
              })}
            />
          </Row>
          <Box>
            {!isJumper ? (
              <PaymentGrid $marginBottom="S">
                <Row>
                  <Switch
                    name="paymentContract"
                    value={switches.paymentContract}
                    onChange={onChangePaymentSwitches}
                  />
                  <Box $marginLeft="S">
                    <H4>{t("additional-info.contract.title", { ns: "add-offer" })}</H4>
                    <P $small $marginBottom="0">
                      {t("additional-info.contract.text", { ns: "add-offer" })}
                    </P>
                  </Box>
                </Row>
                {switches.paymentContract && (
                  <Box>
                    <Box>
                      <Checkbox
                        name="paymentContract"
                        label={t("additional-info.contract.perMonth", { ns: "add-offer" })}
                        value={paymentCheckboxes.paymentContract}
                        onChange={onChangePaymentCheckboxes}
                      />
                      {paymentCheckboxes.paymentContract && (
                        <PaymentInputGrid $marginTop="XS">
                          <Input
                            placeholder={t("additional-info.contract.from", { ns: "add-offer" })}
                            elementType="numeric"
                            name="paymentContractFrom"
                            decimalScale={0}
                            value={watch("paymentContractFrom")}
                            endAdornment={translate(paymentCurrency)}
                            error={errors.paymentContractFrom}
                            rhf={register("paymentContractFrom", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                          <Input
                            placeholder={t("additional-info.contract.to", { ns: "add-offer" })}
                            name="paymentContractTo"
                            elementType="numeric"
                            decimalScale={0}
                            value={watch("paymentContractTo")}
                            error={errors.paymentContractTo}
                            endAdornment={translate(paymentCurrency)}
                            rhf={register("paymentContractTo", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        </PaymentInputGrid>
                      )}
                    </Box>
                    <Box $marginTop="S">
                      <Checkbox
                        name="paymentContractPerDay"
                        label={t("additional-info.contract.perDay", { ns: "add-offer" })}
                        value={paymentCheckboxes.paymentContractPerDay}
                        onChange={onChangePaymentCheckboxes}
                      />
                      {paymentCheckboxes.paymentContractPerDay && (
                        <PaymentInputGrid $marginTop="XS">
                          <Input
                            elementType="numeric"
                            name="paymentContractPerDay"
                            decimalScale={0}
                            value={watch("paymentContractPerDay")}
                            endAdornment={translate(paymentCurrency)}
                            error={errors.paymentContractPerDay}
                            rhf={register("paymentContractPerDay", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        </PaymentInputGrid>
                      )}
                    </Box>
                  </Box>
                )}
              </PaymentGrid>
            ) : (
              <PaymentGrid $marginBottom="S">
                <Row>
                  <Switch
                    name="paymentContract"
                    value={switches.paymentContract}
                    onChange={onChangePaymentSwitches}
                  />
                  <Box $marginLeft="S">
                    <H4>{t("additional-info.jumper-contract.title", { ns: "add-offer" })}</H4>
                    <P $small $marginBottom="0">
                      {t("additional-info.jumper-contract.text", { ns: "add-offer" })}
                    </P>
                  </Box>
                </Row>
                {switches.paymentContract && (
                  <Box>
                    <Box>
                      <Checkbox
                        name="paymentContract"
                        label={t("additional-info.jumper-contract.perMonth", { ns: "add-offer" })}
                        value={paymentCheckboxes.paymentContract}
                        onChange={onChangePaymentCheckboxes}
                      />
                      {paymentCheckboxes.paymentContract && (
                        <PaymentInputGrid $marginTop="XS">
                          <Input
                            placeholder={t("additional-info.jumper-contract.from", {
                              ns: "add-offer",
                            })}
                            elementType="numeric"
                            name="paymentContractFrom"
                            decimalScale={0}
                            value={watch("paymentContractFrom")}
                            endAdornment={translate(paymentCurrency)}
                            error={errors.paymentContractFrom}
                            rhf={register("paymentContractFrom", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                          <Input
                            placeholder={t("additional-info.jumper-contract.to", {
                              ns: "add-offer",
                            })}
                            name="paymentContractTo"
                            elementType="numeric"
                            decimalScale={0}
                            value={watch("paymentContractTo")}
                            error={errors.paymentContractTo}
                            endAdornment={translate(paymentCurrency)}
                            rhf={register("paymentContractTo", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        </PaymentInputGrid>
                      )}
                    </Box>
                    <Box $marginTop="S">
                      <Checkbox
                        name="paymentContractPerDay"
                        label={t("additional-info.jumper-contract.perDay", { ns: "add-offer" })}
                        value={paymentCheckboxes.paymentContractPerDay}
                        onChange={onChangePaymentCheckboxes}
                      />
                      {paymentCheckboxes.paymentContractPerDay && (
                        <PaymentInputGrid $marginTop="XS">
                          <Input
                            elementType="numeric"
                            name="paymentContractPerDay"
                            decimalScale={0}
                            value={watch("paymentContractPerDay")}
                            endAdornment={translate(paymentCurrency)}
                            error={errors.paymentContractPerDay}
                            rhf={register("paymentContractPerDay", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        </PaymentInputGrid>
                      )}
                    </Box>
                  </Box>
                )}
              </PaymentGrid>
            )}
            <PaymentGrid>
              <Row>
                <Switch
                  name="paymentB2B"
                  value={switches.paymentB2B}
                  onChange={onChangePaymentSwitches}
                />
                <Box $marginLeft="S">
                  <H4>{t("additional-info.b2b.title", { ns: "add-offer" })}</H4>
                  <P $small $marginBottom="0">
                    {t("additional-info.b2b.text", { ns: "add-offer" })}
                  </P>
                </Box>
              </Row>
              {switches.paymentB2B && (
                <Box>
                  <Box>
                    <Checkbox
                      name="paymentB2B"
                      label={t(
                        state.type === "jumper"
                          ? "additional-info.jumper-contract.perMonth"
                          : "additional-info.contract.perMonth",
                        { ns: "add-offer" },
                      )}
                      value={paymentCheckboxes.paymentB2B}
                      onChange={onChangePaymentCheckboxes}
                    />
                    {paymentCheckboxes.paymentB2B && (
                      <PaymentInputGrid $marginTop="XS">
                        {switches.paymentB2B && paymentCheckboxes.paymentB2B && (
                          <Input
                            placeholder={t(
                              state.type === "jumper"
                                ? "additional-info.jumper-contract.from"
                                : "additional-info.contract.from",
                              { ns: "add-offer" },
                            )}
                            elementType="numeric"
                            name="paymentB2BFrom"
                            decimalScale={0}
                            value={watch("paymentB2BFrom")}
                            endAdornment={translate(paymentCurrency)}
                            error={errors.paymentB2BFrom}
                            rhf={register("paymentB2BFrom", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        )}
                        {switches.paymentB2B && paymentCheckboxes.paymentB2B && (
                          <Input
                            placeholder={t("additional-info.contract.to", { ns: "add-offer" })}
                            name="paymentB2BTo"
                            elementType="numeric"
                            decimalScale={0}
                            value={watch("paymentB2BTo")}
                            error={errors.paymentB2BTo}
                            endAdornment={translate(paymentCurrency)}
                            rhf={register("paymentB2BTo", {
                              required: t("required", { ns: "form" }),
                            })}
                          />
                        )}
                      </PaymentInputGrid>
                    )}
                  </Box>
                  <Box $marginTop="S">
                    <Checkbox
                      name="paymentB2BPerDay"
                      label={t("additional-info.contract.perDay", { ns: "add-offer" })}
                      value={paymentCheckboxes.paymentB2BPerDay}
                      onChange={onChangePaymentCheckboxes}
                    />
                    {paymentCheckboxes.paymentB2BPerDay && (
                      <PaymentInputGrid $marginTop="XS">
                        <Input
                          elementType="numeric"
                          name="paymentB2BPerDay"
                          decimalScale={0}
                          value={watch("paymentB2BPerDay")}
                          endAdornment={translate(paymentCurrency)}
                          error={errors.paymentB2BPerDay}
                          rhf={register("paymentB2BPerDay", {
                            required: t("required", { ns: "form" }),
                            shouldUnregister: true,
                          })}
                        />
                      </PaymentInputGrid>
                    )}
                  </Box>
                </Box>
              )}
            </PaymentGrid>
          </Box>
        </BlockWrapper>
        <BlockWrapper>
          <H3 $marginBottom="0">{t("additional-info.more-info.title", { ns: "add-offer" })}</H3>
          <Box>
            <Row>
              <Switch
                disabled
                name="description1"
                value={switches.description1}
                onChange={onChangeSwitches}
              />
              <Box $marginLeft="S">
                <H4>
                  {t("additional-info.more-info.responsibilities.title", { ns: "add-offer" })}
                </H4>
                <P $small $marginBottom="0">
                  {t("additional-info.more-info.responsibilities.text", { ns: "add-offer" })}
                </P>
              </Box>
            </Row>
            <RichTextEditor
              name="description1"
              value={descriptions.description1}
              onChange={handleOnChangeRichTextEditor}
              $marginTop="S"
            />
          </Box>
          {!isJumper && (
            <>
              <Box>
                <Row>
                  <Switch
                    name="description2"
                    value={switches.description2}
                    onChange={onChangeSwitches}
                  />
                  <Box $marginLeft="S">
                    <H4>
                      {t("additional-info.more-info.requirements.title", { ns: "add-offer" })}
                    </H4>
                    <P $small $marginBottom="0">
                      {t("additional-info.more-info.requirements.text", { ns: "add-offer" })}
                    </P>
                  </Box>
                </Row>
                {switches.description2 && (
                  <RichTextEditor
                    name="description2"
                    value={descriptions.description2}
                    onChange={handleOnChangeRichTextEditor}
                    $marginTop="S"
                  />
                )}
              </Box>
              <Box>
                <Row>
                  <Switch
                    name="description3"
                    value={switches.description3}
                    onChange={onChangeSwitches}
                  />
                  <Box $marginLeft="S">
                    <H4>{t("additional-info.more-info.we-offer.title", { ns: "add-offer" })}</H4>
                    <P $small $marginBottom="0">
                      {t("additional-info.more-info.we-offer.text", { ns: "add-offer" })}
                    </P>
                  </Box>
                </Row>
                {switches.description3 && (
                  <RichTextEditor
                    name="description3"
                    value={descriptions.description3}
                    onChange={handleOnChangeRichTextEditor}
                    $marginTop="S"
                  />
                )}
              </Box>
            </>
          )}
        </BlockWrapper>
        <BlockWrapper>
          <H3 $marginBottom="0">{t("additional-info.params.title", { ns: "add-offer" })}</H3>
          {state.category === "drivers" && (
            <>
              {!isJumper && (
                <Box>
                  <H4>{t("additional-info.params.work-type", { ns: "add-offer" })}</H4>
                  <CheckboxesGroup
                    name="drivingAreas"
                    $type="secondary"
                    options={DRIVINGAREAS_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                    value={drivingAreas}
                    onChange={({ value }) => setDrivingAreas(value)}
                  />
                </Box>
              )}
              <Box>
                <H4>{t("additional-info.params.truck-type", { ns: "add-offer" })}</H4>
                <CheckboxesGroup
                  name="truckTypes"
                  $type="secondary"
                  options={TRUCKTYPES_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                  value={truckTypes}
                  onChange={({ value }) => setTruckTypes(value)}
                />
              </Box>
              <Box>
                <H4>{t("additional-info.params.cast", { ns: "add-offer" })}</H4>
                <CheckboxesGroup
                  name="workLocations"
                  $type="secondary"
                  options={CAST_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                  value={cast}
                  onChange={({ value }) => {
                    if (value.length === 0) {
                      setExperience(["single_cast"])
                      return
                    }
                    setCast(value)
                  }}
                />
              </Box>
              {!isJumper && (
                <Box>
                  <H4>{t("additional-info.params.work-mode", { ns: "add-offer" })}</H4>
                  <CheckboxesGroup
                    name="workTypes"
                    $type="secondary"
                    options={WORKTYPES_DRIVER_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                    value={workTypes}
                    onChange={({ value }) => setWorkTypes(value)}
                  />
                </Box>
              )}
              {!isJumper && (
                <Box>
                  <H4>{t("additional-info.params.directions", { ns: "add-offer" })}</H4>
                  <CheckboxesGroup
                    name="directions"
                    $type="secondary"
                    options={DIRECTIONS_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                    value={directions}
                    onChange={({ value }) => setDirections(value)}
                  />
                </Box>
              )}
            </>
          )}
          {state.category === "others" && (
            <>
              <Box>
                <H4>{t("additional-info.params.work-type", { ns: "add-offer" })}</H4>
                <CheckboxesGroup
                  name="workTypes"
                  $type="secondary"
                  options={WORKTYPES_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                  value={workTypes}
                  onChange={({ value }) => setWorkTypes(value)}
                />
              </Box>
              <Box>
                <H4>{t("additional-info.params.work-mode", { ns: "add-offer" })}</H4>
                <CheckboxesGroup
                  name="workLocations"
                  $type="secondary"
                  options={WORKLOCATION_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                  value={workLocations}
                  onChange={({ value }) => setWorkLocations(value)}
                />
              </Box>
            </>
          )}
          <Box>
            <H4>{t("additional-info.params.experience", { ns: "add-offer" })}</H4>
            <CheckboxesGroup
              name="workLocations"
              $type="secondary"
              options={EXPERIENCE_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
              value={experience}
              onChange={({ value }) => {
                if (value.length === 0) {
                  setExperience(["exp_no"])
                  return
                }
                setExperience([value.at(-1)])
              }}
            />
          </Box>
          <Box>
            <H4>{t("additional-info.params.languages", { ns: "add-offer" })}</H4>
            <CheckboxesGroup
              name="languages"
              $type="secondary"
              options={LANGUAGES_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
              value={languages}
              onChange={({ value }) => setLanguages(value)}
            />
          </Box>
          <Box>
            <H4>{t("additional-info.params.others", { ns: "add-offer" })}</H4>
            <CheckboxesGroup
              name="drivingAreas"
              $type="secondary"
              options={
                state.category === "drivers"
                  ? PARAMS_DRIVER_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])
                  : PARAMS_OTHERS_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])
              }
              value={params}
              onChange={({ value }) => setParams(value)}
            />
          </Box>
        </BlockWrapper>
        {state.plan === "premium" && (
          <BlockWrapper>
            <Box>
              <H3 $marginBottom="0">{t("additional-info.apply.title", { ns: "add-offer" })}</H3>
              <P $small $marginBottom="0" $marginTop="S">
                {t("additional-info.apply.text", { ns: "add-offer" })}
              </P>
            </Box>
            <Box>
              <Input
                name="applyLink"
                value={watch("applyLink")}
                error={errors.applyLink}
                rhf={register("applyLink", {
                  required: false,
                  pattern: {
                    value: WEBSITE_REGEX,
                    message: t("wrong-website", { ns: "form" }),
                  },
                })}
                startAdornment="https://"
              />
            </Box>
          </BlockWrapper>
        )}
        {state.plan === "premium" && (
          <BlockWrapper>
            <Box>
              <H3 $marginBottom="0">{t("additional-info.survey.title", { ns: "add-offer" })}</H3>
              <P $small $marginBottom="0" $marginTop="S">
                {t("additional-info.survey.text", { ns: "add-offer" })}
              </P>
            </Box>
            <Box>
              <Controller
                name="survey"
                control={control}
                render={({ field }) => (
                  <InputAutocomplete
                    label={`${t("surveyName", { ns: "form" })}`}
                    error={errors.survey}
                    inputValue={surveyInput}
                    onInputChange={setSurveyInput}
                    noOptionsText={t("not-found", { ns: "form" })}
                    loading={loadingSurvey}
                    options={surveysOptions.map(el => {
                      return {
                        id: `${el.id}`,
                        label: el.name,
                        ...el,
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>
          </BlockWrapper>
        )}
        <BlockWrapper className="hide_border">
          <H3 $marginBottom="0">{t("additional-info.legal.title", { ns: "add-offer" })}</H3>
          <Box>
            <Row>
              <Switch
                disabled
                name="legalInformationClause"
                value={switches.legalInformationClause}
                onChange={onChangeSwitches}
              />
              <Box $marginLeft="S">
                <H4>{t("additional-info.legal.info.title", { ns: "add-offer" })}*</H4>
                <P $small $marginBottom="0">
                  {t("additional-info.legal.info.text", { ns: "add-offer" })}
                </P>
              </Box>
            </Row>
            <Input
              name="legalInformationClause"
              value={watch("legalInformationClause")}
              error={errors.legalInformationClause}
              multiline
              rows={12}
              rhf={register("legalInformationClause", {
                required: t("required", { ns: "form" }),
              })}
              $marginTop="S"
            />
          </Box>
          <Box>
            <Row>
              <Switch
                name="legalFutureRecruitment"
                value={switches.legalFutureRecruitment}
                onChange={onChangeSwitches}
              />
              <Box $marginLeft="S">
                <H4>{t("additional-info.legal.future-apply.title", { ns: "add-offer" })}*</H4>
                <P $small $marginBottom="0">
                  {t("additional-info.legal.future-apply.text", { ns: "add-offer" })}
                </P>
              </Box>
            </Row>
            {switches.legalFutureRecruitment && (
              <Input
                name="legalFutureRecruitment"
                value={watch("legalFutureRecruitment")}
                error={errors.legalFutureRecruitment}
                multiline
                rows={6}
                rhf={register("legalFutureRecruitment", {
                  required: t("required", { ns: "form" }),
                })}
                $marginTop="S"
              />
            )}
          </Box>
        </BlockWrapper>
      </>
    </Modal>
  )
}

export default AddOfferAdditioanlInfo

const BlockWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} ${({ theme }) => theme.space.S};
  width: calc(100% + (${({ theme }) => theme.space.S} * 2));
  margin-left: -${({ theme }) => theme.space.S};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};

  &.hide_border {
    border-bottom: none;
  }
`

const PaymentInputGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const PaymentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: ${({ theme }) => theme.space.M};
  //background: ${({ theme }) => theme.color.background};
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const BasicInfoGrid = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.L};
  grid-row-gap: ${({ theme }) => theme.space.S};
  margin-bottom: ${({ theme }) => theme.space.S};
`
